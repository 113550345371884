var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CContainer",
    { staticClass: "d-flex align-items-center min-vh-100" },
    [
      _c(
        "CRow",
        { staticClass: "w-100 justify-content-center" },
        [
          _c("CCol", { attrs: { md: "6" } }, [
            _c(
              "div",
              { staticClass: "w-100" },
              [
                _c("div", { staticClass: "clearfix" }, [
                  _c("h1", { staticClass: "float-left display-3 mr-4" }, [
                    _vm._v("404")
                  ]),
                  _c("h4", { staticClass: "pt-3" }, [
                    _vm._v("存在しないページです。")
                  ]),
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v("不正なURLです。")
                  ])
                ]),
                _c("CInput", {
                  staticClass: "mb-3",
                  attrs: { placeholder: "What are you looking for?" },
                  scopedSlots: _vm._u([
                    {
                      key: "prepend-content",
                      fn: function() {
                        return [
                          _c("CIcon", {
                            attrs: { name: "cil-magnifying-glass" }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "append",
                      fn: function() {
                        return [
                          _c("CButton", { attrs: { color: "info" } }, [
                            _vm._v("検索")
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c("CInput", {
                  staticClass: "mb-3",
                  attrs: { placeholder: "What are you looking for?" },
                  on: {
                    input: function($event) {
                      _vm.keyword = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "prepend-content",
                      fn: function() {
                        return [
                          _c("CIcon", {
                            attrs: { name: "cil-magnifying-glass" }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "append",
                      fn: function() {
                        return [
                          _c(
                            "CButton",
                            {
                              attrs: { color: "info" },
                              on: {
                                click: function($event) {
                                  return _vm.test(1, $event)
                                }
                              }
                            },
                            [_vm._v("検索")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }